<template>
  <div class="p-0">
    <div>
      <b-navbar class="shadow-lg py-1">
        <div class="container">
          <b-navbar-nav class="mr-auto">
            <b-nav-item :to="{ name: 'landing-mechtech' }" style="font-size: 18px; font-weight: 500;" class="mx-1">
              <img :src="require('@/assets/images/logo/logo-text.png')" alt="logo-mechtech" style="width: 25%">
            </b-nav-item>
          </b-navbar-nav>
          <b-navbar-nav class="ml-auto mr-4 pr-4">
            <b-nav-item :to="{ name: 'landing-mechtech' }" style="font-size: 18px; font-weight: 500;" class="mx-1">
              Home
            </b-nav-item>
            <b-nav-item :to="{ name: 'about-mechtech' }" style="font-size: 18px; font-weight: 500;" class="mx-1">
              About Us
            </b-nav-item>
          </b-navbar-nav>
        </div>
      </b-navbar>
    </div>

    <div class="py-4" style="background-color: #990000;">
      <b-row class="animated-section">
        <b-col class="d-flex justify-content-center">
          <h1 style="font-weight: bold; font-size: 36px; color: white;">About</h1>
        </b-col>
      </b-row>
      <b-row class="animated-section">
        <b-col class="d-flex justify-content-center">
          <h1 style="font-weight: bold; font-size: 36px; color: white;">MechTech Indonesia</h1>
        </b-col>
      </b-row>
    </div>


    <div class="py-4 pb-2 animated-section" style="background-color: white;">
      <div class="container my-2 pt-2">
        <b-row class="mb-4">
          <b-col>
            <b-card class="shadow h-100" no-body>
              <b-card-body class="d-flex flex-column text-center">
                <b-card-text>
                  <h1 style="color: #990000; font-size: 38px;">7+</h1>
                  <h3 class="mt-1 mb-0">Kelas Berkualitas</h3>
                </b-card-text>
              </b-card-body>
            </b-card>
          </b-col>
          <b-col>
            <b-card class="shadow h-100" no-body>
              <b-card-body class="d-flex flex-column text-center">
                <b-card-text>
                  <h1 style="color: #990000; font-size: 38px;">20+</h1>
                  <h3 class="mt-1 mb-0">Partner Kerjasama</h3>
                </b-card-text>
              </b-card-body>
            </b-card>
          </b-col>
          <b-col>
            <b-card class="shadow h-100" no-body>
              <b-card-body class="d-flex flex-column text-center">
                <b-card-text>
                  <h1 style="color: #990000; font-size: 38px;">700+</h1>
                  <h3 class="mt-1 mb-0">Peserta Kelas</h3>
                </b-card-text>
              </b-card-body>
            </b-card>
          </b-col>
          <b-col>
            <b-card class="shadow h-100" no-body>
              <b-card-body class="d-flex flex-column text-center">
                <b-card-text>
                  <h1 style="color: #990000; font-size: 38px;">1500+</h1>
                  <h3 class="mt-1 mb-0">Peserta Webinar</h3>
                </b-card-text>
              </b-card-body>
            </b-card>
          </b-col>
        </b-row>
      </div>
    </div>


    <div class="py-4 pb-2 animated-section" style="background-color: white;">
      <div class="container my-2">
        <b-row class="mb-4">
          <b-col class="d-flex justify-content-center">
            <img :src="require('@/assets/images/logo/logo-text.png')" alt="logo-mechtech" style="width: 60%">
          </b-col>
          <b-col>
            <p style="font-size: 16px; letter-spacing: 0.5px;">
              MechTech Indonesia adalah sebuah startup pelatihan software teknik bagi mahasiswa teknik untuk mempersiapkan diri menghadapi dunia kerja di era revolusi industri 4.0 dengan 
              metode pelatihan khusus berbentuk <strong>“Project Based Learning”</strong> yang dirancang agar mahasiswa teknik dapat memiliki pemahaman yang jauh 
              lebih mendalam mengenai teori keteknikan di balik software teknik tersebut dan tidak hanya sebagai operator software yang hanya bisa menekan tombol.
            </p>
          </b-col>
        </b-row>
      </div>
    </div>
    <div class="py-4 pb-2">
      <div class="container">
        <b-row class="mb-4 text-center">
          <b-col class="d-flex flex-column justify-content-center">
            <h2>Visi</h2>
            <span style="font-size: 16px; letter-spacing: 0.5px;">
              Menjadi perusahaan berbasis teknologi kelas dunia yang mendorong terwujudnya Indonesia Emas 2045
            </span>
          </b-col>
        </b-row>
        <b-row>
          <b-col class="mt-2">
            <h2 class="ml-2">Misi</h2>
            <span style="font-size: 16px; letter-spacing: 0.5px;">
              <ul>
                <li>Menjadi platform informatif seputar teknologi yang menginspirasi pemuda untuk membangun negeri</li>
                <li>Menciptakan generasi Indonesia yang memiliki kemampuan bersaing di era revolusi industri 4.0 bidang IPTEK</li>
              </ul>
            </span>
          </b-col>
          <b-col class="text-center">
            <img :src="require('@/assets/images/illustration/about-us.png')" alt="about-us" style="width: 60%">
          </b-col>
        </b-row>
      </div>
    </div>


    <div class="py-4 pb-2" style="background-color: white;">
      <div class="container my-2">
        <b-row class="mb-4 text-center">
          <b-col class="d-flex flex-column justify-content-center">
            <h2>Connect With Us!</h2>
            <span style="font-size: 16px; letter-spacing: 0.5px;">
              Anda dapat menghubungi kami melalui:
            </span>
          </b-col>
        </b-row>
        <b-row>
          <b-col />
          <b-col>
            <a href="mailto:mechtech.idn@email.com" target="_blank">
              <b-card class="shadow h-100" no-body>
                <b-card-body class="d-flex flex-column text-center">
                  <b-card-text>
                    <h1 style="color: #990000; font-size: 38px;">
                      <feather-icon
                        icon="MailIcon"
                        size="36"
                        style="color: #990000"
                      />
                    </h1>
                    <h3 class="mt-1 mb-0">Email Address</h3>
                    <h6 class="mt-1 mb-0">mechtech.idn@gmail.com</h6>
                  </b-card-text>
                </b-card-body>
              </b-card>
            </a>
          </b-col>
          <b-col>
            <a href="https://www.instagram.com/mechtech.class/" target="_blank">
              <b-card class="shadow h-100" no-body>
                <b-card-body class="d-flex flex-column text-center">
                  <b-card-text>
                    <h1 style="color: #990000; font-size: 38px;">
                      <feather-icon
                        icon="InstagramIcon"
                        size="36"
                        style="color: #990000"
                      />
                    </h1>
                    <h3 class="mt-1 mb-0">Instagram</h3>
                    <h6 class="mt-1 mb-0">@mechtech.class</h6>
                  </b-card-text>
                </b-card-body>
              </b-card>
            </a>
          </b-col>
          <b-col>
            <a href="https://wa.me/6282334433773" target="_blank">
              <b-card class="shadow h-100" no-body>
                <b-card-body class="d-flex flex-column text-center">
                  <b-card-text>
                    <h1 style="color: #990000; font-size: 38px;">
                      <feather-icon
                        icon="PhoneIcon"
                        size="36"
                        style="color: #990000"
                      />
                    </h1>
                    <h3 class="mt-1 mb-0">Phone Support</h3>
                    <h6 class="mt-1 mb-0">0823-3443-3773</h6>
                  </b-card-text>
                </b-card-body>
              </b-card>
            </a>
          </b-col>
          <b-col />
        </b-row>
      </div>
    </div>


    <div class="p-2" style="background-color: #43557F">
      <b-row>
        <b-col md="8" class="pl-4 d-flex align-items-center">
          <h1 style="font-weight: bold; font-size: 24px; color: white;">Wujudkan Mimpi Jadi Engineer Profesional Sekarang!</h1>
        </b-col>
        <b-col md="4" class="text-center">
          <a href="https://mechtechidn.mayar.link/" class="display-block mt-auto">
            <b-button variant="outline-light" size="lg">Katalog Produk</b-button>
          </a>
        </b-col>
      </b-row>
    </div>
    <div class="mb-4 py-4 px-2" style="background-color: #2A2D2E">
      <b-row class="mt-2">
        <b-col md="6">
          <b-row>
            <b-col md="3" class="pl-4">
              <img :src="require('@/assets/images/logo/logo-icon.png')" alt="logo-mechtech" style="width: 100%">
            </b-col>
            <b-col md="9" class="pl-0">
              <h1 style="font-weight: bold; font-size: 24px; color: white;">MechTech Indonesia</h1>
              <p>
                MechTech Indonesia adalah sebuah startup pelatihan software teknik bagi mahasiswa teknik untuk mempersiapkan diri menghadapi 
                dunia kerja di era revolusi industri 4.0 dengan metode pelatihan khusus berbentuk <strong>“Project Based Learning”</strong>
              </p>
            </b-col>
          </b-row>
        </b-col>
        <b-col md="3" />
        <b-col md="3">
          <h1 style="font-weight: bold; font-size: 20px; color: white;">Contact</h1>
          <div class="d-flex flex-row">
            <a href="mailto:mechtech.idn@email.com" target="_blank" class="mr-1">
              <feather-icon
                icon="MailIcon"
                size="22"
                style="color: white"
              />
            </a>
            <a href="https://www.linkedin.com/company/mechtech-indonesia/" target="_blank" rel="noopener noreferrer" class="mr-1">
              <feather-icon
                icon="LinkedinIcon"
                size="22"
                style="color: white"
              />
            </a>
            <a href="https://www.instagram.com/mechtech.class/" target="_blank" rel="noopener noreferrer" class="mr-1">
              <feather-icon
                icon="InstagramIcon"
                size="22"
                style="color: white"
              />
            </a>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <hr>
        </b-col>
      </b-row>
      <b-row>
        <b-col class="text-center">
          <p style="color: white; font-weight: bold;">&copy; 2024 MechTech Indonesia. All rights reserved.</p>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LandingPage',
  data() {
    return {
      landingBackground: require('@/assets/images/mechtech/Revisi-Atas-2.png'),
      commentBackground: require('@/assets/images/mechtech/Revisi-Testimoni.png'),
      talentImage: require('@/assets/images/mechtech/talent-1.png'),
      facilities: {
        bootcamp: [
          '2 Sertifikat Internasional',
          'MechTech E-Certificate',
          '3 bulan pembelajaran',
          '28x Live Meeting via Zoom',
          '56 Jam Pembelajaran',
          'Bonus kelas Freelancing',
          'Video Rekaman Kelas',
          '1 0n 1 Mentoring Session',
        ],
        ansys: [
          'E-Certificate',
          '4x Live Meeting via Zoom',
          '2 Minggu Pembelajaran',
          'Modul Materi',
          'Grup Diskusi',
          '1 0n 1 Mentoring Session',
        ],
        artificialIntelligence: [
          'E-Certificate',
          '4x Live Meeting via Zoom',
          '2 Minggu Pembelajaran',
          'Studi Kasus',
          'Modul Materi',
          'Grup Diskusi',
          '1 0n 1 Mentoring Session',
        ],
        projects: [
          'Request (Sesuai Kebutuhan)',
          'Proses Pengerjaan Cepat',
          'Hasil Berkualitas',
          'Harga Terjangkau',
          'Tersedia Revisi',
        ],
        webinar: [
          'E-Certificate',
          'Relasi Baru',
          'Notulensi',
          'Ilmu yang Bermanfaat',
          'Doorprize'
        ],
      },
      classImage: {
        bootcamp: require('@/assets/images/mechtech/bootcamp-image.png'),
        ansys: require('@/assets/images/mechtech/ansys-image.jpg'),
        artificialIntelligence: require('@/assets/images/mechtech/ai-image.jpg'),
        projects: require('@/assets/images/mechtech/project-image.jpg'),
        webinar: require('@/assets/images/mechtech/webinar-image.jpg')
      },
      cards: [
        {
          name: 'Jati Ahmad Purnomo', 
          school: 'Institut Teknologi Sepuluh November', 
          text: 'Terimakasih MechTech sudah mau membimbing mahasiswa teknik jurusan sebelah yang ingin tetap belajar matkul gamtek, bersama mechtech kami jadi tahu harus mulai belajar dari mana.', 
        },
        {
          name: 'Daniar Agistya Wardhana', 
          school: 'Institut Teknologi Sepuluh November', 
          text: 'MechTech emang juara sih. Tentor nya sabar dalam menjawab setiap pertanyaan, tentor juga menguasai setiap materi dan selalu sabar ditanya diluar kelas. Good job MechTech!', 
        },
        {
          name: 'Natasya Habibah', 
          school: 'Universitas Pertahanan', 
          text: 'Pelatihan di Mechtech membuat saya mengetahui dan juga belajar ilmu-ilmu baru yang sulit untuk didapatkan di perkuliahan', 
        },
        {
          name: 'Mogah Sagacious Saragih', 
          school: 'Institut Teknologi Sepuluh November', 
          text: 'Belajar dengan MechTech sangat membuat diri Saya improve di banyak hal, terutama dalam desain & simulasi di aplikasinya, tidak lupa juga teori-teori yang mendukungnya diberikan dengan cukup lengkap sehingga sangat membantu', 
        },
      ],
      partners1: [
        require('@/assets/images/mechtech/partner-smart.jpg'),
        require('@/assets/images/mechtech/partner-glints.png'),
        require('@/assets/images/mechtech/partner-onesia.png'),
        require('@/assets/images/mechtech/partner-vascomm.png'),
      ],
      partners2: [
        require('@/assets/images/mechtech/partner-sejuta.jpg'),
        require('@/assets/images/mechtech/partner-dibimbing.png'),
        require('@/assets/images/mechtech/partner-tsn.jpg'),
        require('@/assets/images/mechtech/partner-supraco.jpg'),
      ],
    }
  },
  methods: {
    onSlideStart(slide) {
      this.sliding = true
    },
    onSlideEnd(slide) {
      this.sliding = false
    }
  }
}
</script>

<style lang="scss">
@import "~@core/scss/base/themes/bordered-layout.scss";

.animated-section {
  animation: fadeInUp 1s ease-out;
}

.animated-title {
  animation: fadeIn 1.5s ease-out;
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
</style>